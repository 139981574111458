import React, { memo, useCallback, useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Grid, Autoplay, Navigation } from 'swiper';
import 'swiper/swiper.min.css';
import 'styles/swiper_custom.css';

interface TextPopupProps {
  isOpenText: boolean;
  textArray: string[];
  textTitleArray: string[];
  textUrlArray: string[];
  currentSlideText?: number;
  closePopupText: () => void;
  handleNotTodayChangeText: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TextPopup: React.FC<TextPopupProps> = ({ textArray, textTitleArray, textUrlArray }) => {
  SwiperCore.use([Autoplay, Navigation]);
  const [text, setText] = useState<string[]>([]);
  const [textUrl, setTextUrl] = useState<string[]>([]);
  const [textCount, setTextCount] = useState<number>(0);
  const swiperRef = useRef<null>(null);
  const [isOpenText, setIsOpenText] = useState(true);
  const [currentSlideText, setCurrentSlideText] = useState<number>(0);
  const [notTodayText, setNotTodayText] = useState<boolean>(false);
  const [notToday, setNotToday] = useState<boolean>(false);

  useEffect(() => {
    const text: string[] = [];
    const textTitle: string[] = [];
    const textUrl: string[] = [];

    for (let i = textArray.length - 1; i >= 0; i--) {
      text.push((textArray[i] as { pc_text?: string })?.pc_text || '');
      textTitleArray.push((textTitleArray[i] as { title?: string })?.title || '');
      textUrl.push((textUrlArray[i] as { pc_url?: string })?.pc_url || '');
    }

    setTextCount(text.length);
    setText(text);
    setTextUrl(textUrl);
  }, [textArray]);

  const nextSlide = () => {
    if (swiperRef.current && isOpenText) {
      (swiperRef.current as any).swiper.slidePrev();
      setCurrentSlideText((swiperRef.current as any).swiper.activeIndex);
    }
  };

  const prevSlide = () => {
    if (swiperRef.current && isOpenText) {
      (swiperRef.current as any).swiper.slideNext();
      setCurrentSlideText((swiperRef.current as any).swiper.activeIndex);
    }
  };

  const closePopupText = () => {
    setIsOpenText(false);
  };

  const handleNotTodayChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNotTodayText(e.target.checked);
    if (e.target.checked) {
      const tomorrow = new Date();
      tomorrow.setDate(new Date().getDate() + 1);
      localStorage.setItem('textPopupCheck', tomorrow.toString());
    } else {
      localStorage.removeItem('textPopupCheck');
    }
  };

  useEffect(() => {
    const imgPopupCheck = localStorage.getItem('textPopupCheck');
    if (imgPopupCheck) {
      const checkDate = new Date(imgPopupCheck);
      const currentDate = new Date();
      if (currentDate < checkDate) {
        setNotToday(true);
        setIsOpenText(false);
      }
    }
  }, []);

  useEffect(() => {
    const checkPopups = () => {
      const imagePopup = document.getElementById('imagePopup');
      const textPopup = document.getElementById('textPopup');

      if (imagePopup || textPopup) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    };

    checkPopups();

    // DOM이 변경될 때마다 checkPopups 함수를 호출합니다.
    const observer = new MutationObserver(checkPopups);
    observer.observe(document, { childList: true, subtree: true });

    // 컴포넌트가 언마운트될 때 observer를 정리합니다.
    return () => observer.disconnect();
  }, []);

  return isOpenText && textArray.length > 0 ? (
    <div className="popup layer-pop" id="textPopup">
      <div className="popup-wrap" style={{ maxWidth: '520px' }}>
        <button className="close-btn" type="button" onClick={closePopupText}>
          <span className="d-none">닫기</span>
        </button>
        <p className="title">{textTitleArray[currentSlideText]}</p>
        <Swiper
          autoplay={{
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          }}
          ref={swiperRef}
          spaceBetween={50}
          slidesPerView={1}
          autoHeight
          onSlideChangeTransitionEnd={(swiper) => setCurrentSlideText(swiper.realIndex)}
        >
          {textArray.map((text: string, index: number) => (
            <SwiperSlide key={index} style={{ maxWidth: '470px' }}>
              <div
                className="text"
                style={{
                  display: 'block',
                  maxWidth: '470px',
                  maxHeight: '300px',
                  overflow: 'auto',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {text}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper-button-wrap" style={{ bottom: '120px', right: '24px' }}>
          <div className="swiper-button-prev" onClick={nextSlide} onKeyDown={nextSlide} role="button" tabIndex={0}>
            {' '}
          </div>
          <div className="swiper-pagination">
            {currentSlideText + 1}/{textCount}
          </div>
          <div className="swiper-button-next" onClick={prevSlide} onKeyDown={prevSlide} role="button" tabIndex={0}>
            {' '}
          </div>
        </div>
        <div className="btn-wrap">
          <button className="close" type="button" onClick={closePopupText}>
            닫기
          </button>
          <div className="check-box-div">
            <div className="check-box">
              <input
                type="checkbox"
                id="notToday2"
                // checked={checked}
                // onChange={(e) => setChecked(e.target.checked)}
                checked={notTodayText}
                onChange={handleNotTodayChangeText}
              />
              <label htmlFor="notToday2" className="label-info">
                오늘하루 보지않기
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default TextPopup;
