import React, { memo, useCallback, useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Grid, Autoplay, Navigation } from 'swiper';
import 'swiper/swiper.min.css';
import 'styles/swiper_custom.css';

interface ImagePopupProps {
  isOpenImg: boolean;
  imageArray: string[];
  currentSlide?: number;
  closePopupImg: () => void;
  handleNotTodayChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ImagePopup: React.FC<ImagePopupProps> = ({ imageArray }) => {
  SwiperCore.use([Autoplay, Navigation]);
  const [images, setImages] = useState<string[]>([]);
  const [imagesTitle, setImagesTitle] = useState<string[]>([]);
  const [imagesUrl, setImagesUrl] = useState<string[]>([]);
  const [imageCount, setImageCount] = useState<number>(0);
  const swiperRef = useRef<null>(null);
  const [isOpenImg, setIsOpenImg] = useState(true);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [notToday, setNotToday] = useState<boolean>(false);

  useEffect(() => {
    const images: string[] = [];
    const imagesTitle: string[] = [];
    const imagesUrl: string[] = [];

    for (let i = imageArray.length - 1; i >= 0; i--) {
      images.push((imageArray[i] as { pc_image?: string })?.pc_image || '');
      imagesTitle.push((imageArray[i] as { title?: string })?.title || '');
      imagesUrl.push((imageArray[i] as { pc_url?: string })?.pc_url || '');
    }

    setImageCount(images.length);
    setImages(images);
    setImagesTitle(imagesTitle);
    setImagesUrl(imagesUrl);
  }, [imageArray]);

  const nextSlide = () => {
    if (swiperRef.current && isOpenImg) {
      (swiperRef.current as any).swiper.slidePrev();
      setCurrentSlide((swiperRef.current as any).swiper.activeIndex);
    }
  };

  const prevSlide = () => {
    if (swiperRef.current && isOpenImg) {
      (swiperRef.current as any).swiper.slideNext();
      setCurrentSlide((swiperRef.current as any).swiper.activeIndex);
    }
  };

  const closePopupImg = () => {
    setIsOpenImg(false);
  };

  const handleNotTodayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNotToday(e.target.checked);
    if (e.target.checked) {
      const tomorrow = new Date();
      tomorrow.setDate(new Date().getDate() + 1);
      localStorage.setItem('imgPopupCheck', tomorrow.toString());
    } else {
      localStorage.removeItem('imgPopupCheck');
    }
  };

  useEffect(() => {
    const imgPopupCheck = localStorage.getItem('imgPopupCheck');
    if (imgPopupCheck) {
      const checkDate = new Date(imgPopupCheck);
      const currentDate = new Date();
      if (currentDate < checkDate) {
        setNotToday(true);
        setIsOpenImg(false);
      }
    }
  }, []);

  return isOpenImg && imageArray.length > 0 ? (
    <div className="popup layer-pop" id="imagePopup">
      <div className="popup-wrap">
        <button className="close-btn" type="button" onClick={closePopupImg}>
          <span className="d-none">닫기</span>
        </button>
        <p className="title">{imagesTitle[currentSlide]}</p>
        <div className="layer-content">
          <Swiper
            autoplay={{
              delay: 3000,
              stopOnLastSlide: false,
              disableOnInteraction: false,
            }}
            ref={swiperRef}
            spaceBetween={50}
            slidesPerView={1}
            navigation
            autoHeight
            onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <a href={imagesUrl[currentSlide]} target="_blank" rel="noopener noreferrer">
                  <img className="popup-img" src={image} alt={`테스트용 이미지 ${index}`} />
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="swiper-button-wrap" style={{ bottom: '120px', right: '24px' }}>
          <div className="swiper-button-prev" onClick={nextSlide} onKeyDown={nextSlide} role="button" tabIndex={0}>
            {' '}
          </div>
          <div className="swiper-pagination">
            {currentSlide + 1}/{imageCount}
          </div>
          <div className="swiper-button-next" onClick={prevSlide} onKeyDown={prevSlide} role="button" tabIndex={0}>
            {' '}
          </div>
        </div>
        <div className="btn-wrap">
          <button className="close" type="button" onClick={closePopupImg}>
            닫기
          </button>
          <div className="check-box-div">
            <div className="check-box">
              <input type="checkbox" id="notToday3" checked={notToday} onChange={handleNotTodayChange} />
              <label htmlFor="notToday3" className="label-info">
                오늘하루 보지않기
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ImagePopup;
