import React, { memo, useCallback, useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Grid, Autoplay, Navigation } from 'swiper';
import 'swiper/swiper.min.css';
import 'styles/swiper_custom.css';

interface BottomPopupProps {
  isOpenBottom: boolean;
  bottomArray: string[];
  bottomTitleArray: string[];
  bottomUrlArray: string[];
  currentSlideBottom?: number;
  closePopupBottom: () => void;
  handleNotTodayChangeBottom: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const BottomPopup: React.FC<BottomPopupProps> = ({ bottomArray, bottomTitleArray, bottomUrlArray }) => {
  SwiperCore.use([Autoplay, Navigation]);
  const [bottom, setBottom] = useState<string[]>([]);
  const [bottomImg, setBottomImg] = useState<string[]>([]);
  const [bottomUrl, setBottomUrl] = useState<string[]>([]);
  const [bottomCount, setBottomCount] = useState<number>(0);
  const swiperRef = useRef<null>(null);
  const [isOpenBottom, setIsOpenBottom] = useState(true);
  const [currentSlideBottom, setCurrentSlideBottom] = useState(0);
  const [notTodayBottom, setNotTodayBottom] = useState(false);

  useEffect(() => {
    const bottomImg: string[] = [];
    const bottomTitle: string[] = [];
    const bottomUrl: string[] = [];

    for (let i = bottomArray.length - 1; i >= 0; i--) {
      if (bottomArray[i]) {
        bottom.push(bottomArray[i]);
      }
      if (bottomTitleArray[i]) {
        bottomImg.push(bottomTitleArray[i]);
      }
      if (bottomUrlArray[i]) {
        bottomUrl.push(bottomUrlArray[i]);
      }
    }

    if (bottom.length > 0 || bottomImg.length > 0 || bottomUrl.length > 0) {
      setBottomCount(bottomArray.length);
      setBottomImg(bottomImg);
      setBottomUrl(bottomUrl);
    }
  }, [bottomArray, bottomTitleArray, bottomUrlArray]);

  const nextSlide = () => {
    if (swiperRef.current && isOpenBottom) {
      (swiperRef.current as any).swiper.slidePrev();
      setCurrentSlideBottom((swiperRef.current as any).swiper.activeIndex);
    }
  };

  const prevSlide = () => {
    if (swiperRef.current && isOpenBottom) {
      (swiperRef.current as any).swiper.slideNext();
      setCurrentSlideBottom((swiperRef.current as any).swiper.activeIndex);
    }
  };
  const closePopupBottom = () => {
    setIsOpenBottom(false);
  };

  const handleNotTodayChangeBottom = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNotTodayBottom(e.target.checked);
    if (e.target.checked) {
      const tomorrow = new Date();
      tomorrow.setDate(new Date().getDate() + 1);
      localStorage.setItem('hideUntilBottom', tomorrow.toString());
    } else {
      localStorage.removeItem('hideUntilBottom');
    }
  };

  useEffect(() => {
    const hideUntilBottom = new Date(localStorage.getItem('hideUntilBottom') || '');
    if (hideUntilBottom && hideUntilBottom > new Date()) {
      setNotTodayBottom(true);
      setIsOpenBottom(false);
    }
  }, []);

  return isOpenBottom && bottomArray.length > 0 ? (
    <>
      <div
        className="bottom-sheet-pop popup"
        style={{
          overflow: 'hidden',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
        }}
      >
        <Swiper
          autoplay={{
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          }}
          ref={swiperRef}
          spaceBetween={0}
          slidesPerView={1}
          autoHeight
          onSlideChangeTransitionEnd={(swiper) => setCurrentSlideBottom(swiper.realIndex)}
        >
          {bottomArray.map((image, index) => (
            <SwiperSlide key={index}>
              <a href={bottomUrlArray[currentSlideBottom]} target="_blank" rel="noopener noreferrer">
                <img className="bottom-img" src={image} alt="테스트용 이미지" />
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper-button-wrap" style={{ bottom: '60px', right: '4px' }}>
          <div className="swiper-button-prev" onClick={nextSlide} onKeyDown={nextSlide} role="button" tabIndex={0}>
            {' '}
          </div>
          <div className="swiper-pagination">
            {currentSlideBottom + 1}/{bottomCount}
          </div>
          <div className="swiper-button-next" onClick={prevSlide} onKeyDown={prevSlide} role="button" tabIndex={0}>
            {' '}
          </div>
        </div>
        <div className="btn-wrap">
          <div className="check-box-div">
            <div className="check-box">
              <input
                type="checkbox"
                id="notToday"
                // checked={checked}
                // onChange={(e) => setChecked(e.target.checked)}
                checked={notTodayBottom}
                onChange={handleNotTodayChangeBottom}
              />
              <label htmlFor="notToday" className="label-info">
                오늘하루 보지않기
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-sheet-pop popup">
        <button className="close" type="button" onClick={closePopupBottom}>
          <span className="d-none">닫기</span>
        </button>
      </div>
    </>
  ) : null;
};

export default BottomPopup;
