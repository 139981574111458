import { ComponentClass, FunctionComponent, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import * as store from 'stores';

import type { IGroupState } from '@types';
import { RootState, useAppSelector } from 'redux/store';
import { PATHAUTH, PATHCOAGENCY } from 'utils/path';

import { protectedRouteObject } from '../../@types/props';

type ComponentType<P = {}> = ComponentClass<P> | FunctionComponent<P>;
type Props = {
  component?: ComponentType | React.ReactNode | React.ReactElement | JSX.Element | React.ReactPortal | any;
  location?: string;
  groupState?: IGroupState;
  setGroupState?: (groupState: IGroupState) => void;
  route?: protectedRouteObject;
};

/**
 * @description ProtectedRoute
 * @Class
 * @category App Router
 * @subcategory Router
 */
const ProtectedRoute = ({ component: Component, route, ...props }: Props) => {
  const isLogin = useAppSelector((state: RootState) => state.login.isLogin);
  // 중개사인증 여부 체크
  const isVerified = useAppSelector((state: RootState) => state.user.brokerVerified);
  const infoMe = useAppSelector((state: RootState) => state.user.me);
  const currentUser = useAppSelector((state: RootState) => state.user.currentUser);

  const brokerVerifiedAt = useMemo(() => {
    if (!isVerified) return null;
    return infoMe?.verify?.created_at || currentUser?.verify?.created_at || null;
  }, [isVerified, infoMe, currentUser]);

  const isBrokerPath = useMemo(() => {
    const path = route?.path;

    return path && Object.values(PATHCOAGENCY).includes(path) && brokerVerifiedAt === null;
  }, [route, brokerVerifiedAt]);

  return (
    <>
      {!isLogin ? (
        <Navigate to="/" state={{ from: props.location }} replace />
      ) : isBrokerPath ? (
        <Navigate to={`${PATHAUTH.BROKERNEW}`} state={{ from: props.location }} replace />
      ) : (
        <Component {...props} />
      )}
    </>
  );
};

export default ProtectedRoute;
